import AdvocateDivyaDetails from '../../components/about/advocates-details/divya';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateDivyaDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Divya Dokka'>
      <NavigationMenu active='about' />
      <PageHeader
        title='Divya Dokka'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateDivyaDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateDivyaDetailsPage;
