import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/divya.jpg';

const AdvocateDivyaDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Ms. Divya did her Engineering and with a great passion in law
                did her LLB and graduated from Acharya Nagarjuna University. She
                enrolled as an Advocate in the year 2021. She is specialised in
                the areas of Civil Laws, Criminal Laws, Intellectual Property
                Laws and Family Laws. She is currently practising at Hon’ble
                High Court of Telangana, Civil Courts and Criminal Courts at
                Hyderabad.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  Ms. Divya Dokka. BTech., LLB.
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateDivyaDetails;
